.user-card {
  width: 94%;
  min-height: 80px;
  border: 2px solid #141a2d;
  border-radius: 5px;
  transition: scale 0.2s ease-in-out, box-shadow 0.2s ease-in-out,
    max-height 0.4s ease;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 15px;
  padding: 10px;
  box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.1);
  position: relative;
  overflow: hidden;
}

.user-card:not(.expanded) {
  max-height: 80px;
}

/* When the card is expanded, the max-height increases to accommodate the content */
.user-card.expanded {
  max-height: 1000px; /* Adjust based on the content size */
}

.user-card:hover {
  cursor: pointer;
  scale: 1.01;
  box-shadow: 6px 6px 15px rgba(0, 0, 0, 0.15);
}

.user-info-section {
  margin-left: 20px;
}

/* Adjust styling as needed */
.admin-access {
  border-radius: 5px;
  padding: 10px;
  background-color: #ef5e2e;
  color: white;
  margin: 5px;
}

.invis-placeholder {
  height: 200px;
}

.user-card-top-section {
  display: flex;
  flex-direction: row;
  align-items: center;
  position: absolute;
  height: 80px;
  top: 10px;
  left: 10px;
}

.user-card-bottom-section {
  position: absolute;
  height: 80px;
  top: 100px;
  left: 10px;
  width: 98.4%;
}

.admin-access-placeholder {
  height: 49px;
  /* possibly other styling to match the layout of .admin-access without visible content */
  visibility: hidden; /* this makes the box take up space but remain invisible */
}

.icon-svg {
  scale: 1;
}

.status-boxes {
  display: flex;
  flex-direction: column;
  align-items: right;
  justify-content: top;
  position: absolute;
  top: 0px;
  right: 0px;
}

.setup-status {
  border-radius: 5px;
  padding: 5px; /* Adjust padding as needed */
  width: auto; /* Adjust width as needed */
}

/* For when setup is complete */
.setup-complete {
  margin: 5px;
  padding: 10px;
  background-color: #d4edda; /* Light green */
  color: #155724; /* Dark green */
  border-radius: 5px;
}

/* For when setup is incomplete */
.setup-incomplete {
  margin: 5px;
  padding: 10px;
  background-color: #f8d7da; /* Light red */
  color: #721c24; /* Dark red */
  border-radius: 5px;
}

.user-info-section h5 {
  margin: 0;
}

.user-info-section h3 {
  margin-bottom: 4px;
}

.user-info-section p {
  margin-top: 5px;
}

.user-card-expanded-content > .checkbox-group {
  display: grid;
  grid-template-rows: repeat(
    3,
    auto
  ); /* Three rows to accommodate the checkboxes */
  grid-auto-flow: column; /* Directs the grid to fill columns from top to bottom */
  gap: 0px 15px;
  max-height: fit-content; /* Ensures the grid container does not grow beyond its contents */
}

/* Hide the default checkbox */
.checkbox-group input[type="checkbox"] {
  display: none;
}

/* Create a custom box that will be the checkbox */
.checkbox-group .custom-checkbox {
  display: inline-block;
  margin-right: 10px;
  width: 20px;
  height: 20px;
  background: #fafafa;
  border: 1px solid #d5d5d5;
  border-radius: 5px;
  cursor: pointer;
  position: relative;
}

/* Style for when the custom checkbox is checked */
.checkbox-group input[type="checkbox"]:checked + .custom-checkbox {
  background: #3fbeea;
  border-color: #3fbeea;
}

/* Checkmark (hidden when not checked) */
.checkbox-group .custom-checkbox::after {
  content: "";
  position: absolute;
  top: 2px;
  left: 6px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
  opacity: 0;
}

/* Show checkmark when checked */
.checkbox-group input[type="checkbox"]:checked + .custom-checkbox::after {
  opacity: 1;
}

.update-btn {
  width: 240px;
  height: 60px;
  background-color: #3fbeea;
  border: 1.5px solid #3fbeea;
  color: #223651;
  font-size: 18px;
  transition: 0.15s;
  border-radius: 4px;
  font-weight: 900;
}

.update-btn:hover {
  cursor: pointer;
  background-color: #223651;
  color: #3fbeea;
}

.delete-user-btn {
  padding: 5px 10px;
  background-color: #f44336; /* Red background */
  color: white; /* White text */
  border: none;
  border-radius: 4px;
  cursor: pointer;
  width: 180px;
  height: 35px;
  font-size: 18px;
  font-weight: 600;
  margin-top: 80px;
  /* Position it on the right or wherever you prefer */
}

.delete-user-btn:hover {
  background-color: #c0392b; /* Darker red on hover */
}

.user-card-expanded-content {
  display: flex;
  flex-direction: column;
  justify-content: left;
}

.user-additional-info {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  height: 110px;
}
