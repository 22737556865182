.loading-dots {
  display: flex;
  justify-content: center;
  align-items: center;
}

.dot {
  width: 8px;  /* Smaller size */
  height: 8px; /* Smaller size */
  margin: 0 4px;
  margin-top: 8px;
  background-color: #bbb; /* Lighter gray */
  border-radius: 50%;
  animation: jump 1.5s infinite;
}

.dot:nth-child(1) {
  animation-delay: 0s;
}

.dot:nth-child(2) {
  animation-delay: 0.3s;
}

.dot:nth-child(3) {
  animation-delay: 0.6s;
}

@keyframes jump {
  0%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-5px); /* Jump height */
  }
}