/* ==========================================================================
   Global Container
   ========================================================================== */
.audio-bot-container {
  display: flex;
  height: calc(100vh - 73px);
  background-color: #f5f5f5;
}

/* ==========================================================================
     Sidebar (Optional - for event log)
     ========================================================================== */
/* Uncomment and adjust if you're using a left sidebar */
/*
  .left-sidebar {
    width: 20%;
    background-color: #fff;
    padding: 20px;
    box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
  
  .left-sidebar h2 {
    margin-bottom: 12px;
  }
  
  .event-log {
    list-style: none;
    padding: 0;
    margin: 0;
    overflow-y: auto;
    font-size: 14px;
    line-height: 1.4;
  }
  
  .event-log li {
    margin-bottom: 8px;
  }
  */

/* ==========================================================================
     Main Content (Messages + Input)
     ========================================================================== */
.main-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  background: #ffffff;
  position: relative;
}

/* --------------------------------------------------------------------------
     Scrolling Messages Region
     -------------------------------------------------------------------------- */
.messages {
  flex: 1;
  overflow-y: auto;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

/* --------------------------------------------------------------------------
     Message Styling
     -------------------------------------------------------------------------- */
.message {
  padding: 2px 16px;
  border-radius: 16px;
  line-height: 1.4;
  font-size: 15px;
  max-width: 80%;
  box-shadow: 0 2px 5px rgb(0 0 0 / 0.1);
}

/* User Message (Blue) */
.user-message {
  background-color: #2f3556;
  color: #fff;
  align-self: flex-end;
  border-bottom-right-radius: 0;
}

/* Assistant Message (Gray) */
.assistant-message {
  background-color: #f1f0f0;
  color: #000;
  align-self: flex-start;
  border-bottom-left-radius: 0;
  min-height: fit-content;
}

/* System Messages (Centered) */
.system-message {
  color: #888;
  font-weight: bold;
  text-align: center;
  width: 100%;
  max-width: 100%;
  border-bottom: 1px solid #888;
  padding: 0;
  background: none;
  box-shadow: none;
  border-radius: 0;
}

/* Old Messages (Blurred & Faded) */
.old-message {
  opacity: 0.5;
  filter: blur(1px);
}

/* ==========================================================================
     Input Section
     ========================================================================== */
.input-section {
  display: flex;
  gap: 10px;
  align-items: center;
  padding: 10px 20px;
  border-top: 1px solid #ddd;
  background: #fff;
  flex-shrink: 0;
}

.message-input {
  flex: 1;
  padding: 10px 14px;
  border: 1px solid #ddd;
  border-radius: 20px;
  font-size: 16px;
  outline: none;
}

.message-input:disabled {
  background-color: #e0e0e0;
  cursor: not-allowed;
}

/* Send Button */
.send-button {
  width: 42px;
  height: 42px;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  background-color: #2f3556;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 0.2s ease;
}

.send-button img {
  width: 20px;
  height: 20px;
}

.mic-button {
  width: 42px;
  height: 42px;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  background-color: #2f3556;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 0.2s ease;
}

.mic-button img {
  width: 20px;
  height: 20px;
}

.send-button:hover {
  background-color: #c09152;
}

.send-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.mic-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}


/* ==========================================================================
     Session Configuration Panel
     ========================================================================== */
.session-config {
  width: 25%;
  background: #ffffff;
  padding: 20px;
  box-shadow: 2px 0 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  border-right: 0.5px solid #000;
}

/* Instruction Input */
.instruction-input {
  width: 100%;
  height: 120px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 6px;
  font-size: 14px;
  resize: none;
  outline: none;
  transition: border 0.3s;
}

.instruction-input:focus {
  border-color: #007bff;
}

/* Voice Dropdown (Native Style) */
.voice-dropdown {
  width: 100%;
  padding: 10px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 6px;
  outline: none;
  background-color: #fff;
  cursor: pointer;
  transition: border 0.3s;
}

.voice-dropdown:focus {
  border-color: #007bff;
}

/* Apply Button */
.apply-button {
  width: 100%;
  padding: 12px;
  font-size: 16px;
  font-weight: bold;
  border: none;
  border-radius: 6px;
  background-color: #007bff;
  color: #fff;
  cursor: pointer;
  transition: background 0.3s, transform 0.2s;
}

.apply-button:hover {
  background-color: #0056b3;
  transform: scale(1.05);
}

.apply-button:active {
  transform: scale(0.98);
}

.apply-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

/* ==========================================================================
     Overlays & Disabled States
     ========================================================================== */
.disabled-overlay {
  position: relative;
  pointer-events: none;
  background-color: rgba(0, 0, 0, 0.8);
  opacity: 1;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(4px);
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  z-index: 10;
}

/* ==========================================================================
     Custom Dropdown Styling
     ========================================================================== */
.custom-dropdown {
  position: relative;
  width: 100%;
  font-family: "Arial", sans-serif;
}

/* Dropdown Header (Button) */
.dropdown-header {
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 8px 12px;
  font-size: 14px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: border-color 0.3s ease;
}

.dropdown-header:focus,
.dropdown-header:hover {
  border-color: #007bff;
}

.dropdown-header::after {
  content: "\25BC";
  font-size: 12px;
  color: #666;
  margin-left: 8px;
}

/* Dropdown Options List */
.dropdown-options {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-top: 4px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 100;
  list-style: none;
  padding: 0;
  max-height: 200px;
  overflow-y: auto;
  opacity: 0;
  transform: translateY(-5px);
  pointer-events: none;
  transition: opacity 0.3s ease, transform 0.3s ease;
}

.custom-dropdown.open .dropdown-options {
  opacity: 1;
  transform: translateY(0);
  pointer-events: auto;
}

/* Dropdown Items */
.dropdown-item {
  padding: 8px 12px;
  font-size: 14px;
  cursor: pointer;
  transition: background 0.2s ease;
}

.dropdown-item:hover {
  background: #f1f1f1;
}

.dropdown-item.selected {
  background: #e9e9e9;
  font-weight: bold;
}

/* ==========================================================================
     Spinner Animation
     ========================================================================== */
.spinner {
  width: 50px;
  height: 50px;
  border: 6px solid rgba(255, 255, 255, 0.3);
  border-top: 6px solid #fff;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

.mic-button.active {
  background-color: #c09152; /* or any color you prefer for the active state */
  /* You can also add any additional styling changes you want when active */
  /* For example, a border or box-shadow: */
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.3);
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
