.box-section {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: left;
  align-items: top;
}

.homepage-content {
  background-color: #f3f3f3;
  height: 100vh;
}

.card {
  width: 350px;
  height: 250px;
  overflow: hidden;
  margin: 25px;
  border: 3px solid #223651;
  border-radius: 10px;
  box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
  background-color: #fff;
}

.card:hover {
  transform: translateY(-5px);
  box-shadow: 6px 6px 15px rgba(0, 0, 0, 0.15);
  cursor: pointer;
}

.card img {
  width: 100%;
  height: 150px;
  object-fit: cover;
  object-position: 50% 40%;
}

.img-div {
  position: relative;
}

#cocopah_img {
  display: flex;
  flex-direction: column;
  align-items: center;
}

#cocopah_img img {
  width: auto;
  max-height: 150px;
}

.card-info h4 {
  margin: 15px 0 0 25px;
  color: #555555;
  font-size: 20px;
}

.card-info {
  background-color: #fff;
  height: calc(100% - 100px);
  margin: 0px;
}
