.create-btn {
  width: 240px;
  height: 54px;
  background-color: #3fbeea;
  border: 1.5px solid #3fbeea;
  color: #223651;
  font-size: 18px;
  transition: 0.15s;
  border-radius: 4px;
  font-weight: 900;
}

.create-btn:hover {
  cursor: pointer;
  background-color: #223651;
  color: #3fbeea;
}

.vert-line {
  width: 0.1px;
  margin: 25px 30px 0 0;
  min-height: max-content;
  border: 1.5px solid #141a2d;
  border-radius: 50px;
}

.admin-content {
  display: flex;
  flex-direction: row;
  /* justify-content: space-between; */
  align-items: top;
}

.admin-account-section {
  width: 70%;
}

.admin-create-account-section {
  display: flex;
  flex-direction: column;
  justify-content: left;
}

.text-input-class {
  display: flex;
  flex-direction: column;
  align-items: left;
  width: 220px;
  gap: 5px;
  margin-top: 10px;
  color: #555555;
}

.text-input-class input {
  height: 25px;
  margin: 2px 0 15px 0;
  font-size: 16px;
  border: 1px solid #223651;
  color: #000;
  background-color: rgba(255, 255, 255, 0.04);
  border-radius: 4px;
  padding-left: 6px;
}

/* Hide the default checkbox */
.checkbox-group input[type="checkbox"] {
  display: none;
}

/* Create a custom box that will be the checkbox */
.checkbox-group .custom-checkbox {
  display: inline-block;
  margin-right: 10px;
  width: 20px;
  height: 20px;
  background: #fafafa;
  border: 1px solid #d5d5d5;
  border-radius: 5px;
  cursor: pointer;
  position: relative;
}

/* Style for when the custom checkbox is checked */
.checkbox-group input[type="checkbox"]:checked + .custom-checkbox {
  background: #3fbeea;
  border-color: #3fbeea;
}

/* Checkmark (hidden when not checked) */
.checkbox-group .custom-checkbox::after {
  content: "";
  position: absolute;
  top: 2px;
  left: 6px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
  opacity: 0;
}

/* Show checkmark when checked */
.checkbox-group input[type="checkbox"]:checked + .custom-checkbox::after {
  opacity: 1;
}
