.typewriter-text {
  font-size: 32px; /* Same size as the cursor to ensure alignment */
  color: #333333;
  margin-top: 8px;
  display: inline; /* Inline display to ensure the typewriter effect */
  white-space: pre-wrap; /* Ensures spaces and line breaks are shown */
}

.typewriter .cursor {
  font-size: 40px; /* Same as the .typewriter-text for consistent alignment */
  font-weight: 250;
  animation: blink 1.5s step-end infinite;
  display: inline; /* Keep the display as inline to allow cursor to follow text properly */
  /* Add vertical align if cursor appears too high or too low */
  vertical-align: baseline;
}

.typewriter {
  width: 50%;
  text-align: center;
}

@keyframes blink {
  from,
  to {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}
