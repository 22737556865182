.bc-main-content {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 50px;
  gap: 50px;
}

.styled-select {
  padding: 10px 10px; /* Adjust vertical and horizontal padding */
  font-size: 16px;
  line-height: 1.5; /* Adjust line height for better text display */
  border: 0;
  border-radius: 5px;
  width: 240px;
  height: 46px; /* Change height to auto for flexible sizing */
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: #f2f2f2;
  color: #333;
  cursor: pointer;
}

.styled-select:focus {
  outline: none;
}

.bc-input {
  display: flex;
  flex-direction: column;
}
