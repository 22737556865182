.login-section {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.logo-img {
  width: 80px;
  margin: 25px;
}

.login-form {
  display: flex;
  flex-direction: column;
  justify-content: top;
  align-items: center;
}

.login-btn {
  width: 240px;
  height: 54px;
  background-color: #3fbeea;
  border: 1.5px solid #3fbeea;
  color: #223651;
  font-size: 18px;
  transition: 0.15s;
  border-radius: 4px;
  font-weight: 900;
  margin: 20px;
}

.login-btn:hover {
  cursor: pointer;
  background-color: #223651;
  color: #3fbeea;
}

.input-class {
  display: flex;
  flex-direction: column;
  align-items: left;
  width: 240px;
  gap: 5px;
  color: #555555;
}

.input-class input {
  height: 25px;
  margin: 2px 0 15px 0;
  font-size: 16px;
  border: 1.5px solid #223651;
  color: #000;
  background-color: rgba(255, 255, 255, 0.04);
  border-radius: 4px;
  padding-left: 6px;
}

.alert-error {
  margin: 5px;
  padding: 10px;
  background-color: #f8d7da; /* Red */
  color: #721c24;
  border-radius: 5px;
  width: 200px;
}
