.toyota-main-content {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.toyota-bottom-content {
  background-color: #223651ff;
  border-radius: 8px;
  padding: 25px;
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.label-file-div {
  margin: 15px;
  color: #fff;
}

.toyota-file-drop {
  margin-top: 7px;
}

.date-selectors {
  display: flex;
  width: 80%;
  height: 50px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.toyota-btn {
  width: 240px;
  height: 54px;
  background-color: #3fbeea;
  border: 1.5px solid #3fbeea;
  color: #223651;
  font-size: 18px;
  transition: 0.15s;
  border-radius: 4px;
  font-weight: 900;
}

.toyota-btn:hover {
  cursor: pointer;
  background-color: #223651;
  color: #3fbeea;
}

.spacer {
  height: 15px;
}
