.info-icon-container {
  position: relative;
  display: inline-block;
  width: 18px;
  height: 18px;
  margin-bottom: 3px;
}

.info-icon {
  width: 18px;
  height: 18px;
  cursor: pointer;
  display: block;
}

.info-popup {
  width: 220px;
  padding: 10px;
  background: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 5px;
  z-index: 999; /* Ensure it's above most elements */
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  color: black;
  font-size: 14px;
  /* Remove the arrow styles if they still exist */
}
