.navbar {
  height: 73px;
  width: 100%;
  background-color: #dedede;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.logo {
  height: 44px;
  width: 44px;
  margin-left: 30px;
  margin-top: 15px;
  transition: 0.3s;
}

.logo:hover {
  cursor: pointer;
  scale: 1.15;
}

.logout-btn {
  width: 100px;
  height: 35px;
  background-color: #dedede;
  color: #223651;
  border: 1.5px solid #223651;
  border-radius: 4px;
  font-size: 16px;
  transition: 0.2s;
  margin: 19px;
}

.logout-btn:hover {
  background-color: #223651;
  color: #fff;
  border-color: #223651;
  cursor: pointer;
}
