.beau-content {
  width: 100%;
}

.beau-btn {
  width: 240px;
  height: 54px;
  background-color: #3fbeea;
  border: 1.5px solid #3fbeea;
  color: #223651;
  font-size: 18px;
  transition: 0.15s;
  border-radius: 4px;
  font-weight: 900;
  margin-bottom: 50px;
}

.beau-btn:hover {
  cursor: pointer;
  background-color: #223651;
  color: #3fbeea;
}
.joke-gen-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.beau-input {
  height: 25px;
  width: 232px;
  margin: 40px 0 15px 0;
  font-size: 16px;
  border: 1px solid #223651;
  color: #000;
  background-color: rgba(255, 255, 255, 0.04);
  border-radius: 4px;
  padding-left: 6px;
}

.spinner {
  border: 6px solid #f3f3f3;
  border-top: 6px solid #3fbeea;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  animation: spin 1s linear infinite;
  margin: 20px auto; /* Center the spinner and give it some vertical space */
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
