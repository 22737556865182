#heatmap-iframe {
  position: fixed;
  top: 0px;
  left: 0;
  z-index: -1;
  height: 100%;
}

#current-heatmap {
  position: fixed;
  top: 0;
  left: 0;
  z-index: -2;
  height: 100%;
}

.side-menu {
  z-index: 999;
}

.lt-logo-heatmap {
  position: absolute;
  top: 15px;
  left: 60px;
  transition: 0.3s;
}

.lt-logo-heatmap img {
  width: 60px;
}

.lt-logo-heatmap:hover {
  scale: 1.1;
  cursor: pointer;
}
