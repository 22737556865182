.chat-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100%;
}

.chat-window {
  flex-grow: 1;
  padding: 20px;
  overflow-y: auto; /* Ensure scrolling */
  display: flex;
  flex-direction: column;
}

.message-box {
  max-width: 60%;
  margin: 10px 0;
  padding: 10px;
  border-radius: 10px;
  word-wrap: break-word;
  flex-shrink: 0; /* Prevent bubbles from shrinking */
}

.message-box h1 {
  margin: 5px 0px;
}

.message-box h2 {
  margin: 5px 0px;
}
.message-box h3 {
  margin: 5px 0px;
}
.message-box h4 {
  margin: 5px 0px;
}
.message-box h5 {
  margin: 5px 0px;
}
.message-box h6 {
  margin: 5px 0px;
}


.user-message {
  align-self: flex-end;
  background-color: #223651ff;
  color: #fff;
}

.assistant-message {
  align-self: flex-start;
  background-color: #f1f0f0;
  min-height: 20px;
}

.input-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: end;
  padding: 10px;
  height: fit-content;
  background-color: #223651ff;
}

.input-container textarea {
  margin: 10px;
  height: 38px;
  max-height: 100px;
  width: 400px;
  border-radius: 8px;
  border: 1px solid #ffffff;
  background-color: #223651ff;
  color: #ffffff;
  font-size: 16px;
  padding: 8px;
  resize: none; /* Prevent manual resizing by the user */
  overflow-y: scroll; /* Enable vertical scrollbar */
}

.input-container textarea:focus {
  outline: none; /* Remove blue outline on focus */
}

/* For most modern browsers */
.input-container textarea::placeholder {
  color: #ffffff99;
}

/* For older versions of Firefox */
.input-container textarea:-moz-placeholder {
  color: #ffffff99;
}

/* For older versions of Internet Explorer */
.input-container textarea:-ms-input-placeholder {
  color: #ffffff99;
}

/* For WebKit browsers (Chrome, Safari, etc.) */
.input-container textarea::-webkit-input-placeholder {
  color: #ffffff99;
}

.input-container button {
  width: 125px;
  height: 54px;
  background-color: #3fbeea;
  border: 1.5px solid #3fbeea;
  color: #223651;
  font-size: 18px;
  transition: 0.15s;
  border-radius: 4px;
  font-weight: 900;
  cursor: pointer;
  margin-bottom: 10px;
}

.input-container button:disabled {
  cursor: not-allowed;
  opacity: 0.6; /* Optional: to visually indicate the button is disabled */
}