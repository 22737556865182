.redirect-form-container {
  width: 33%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 50px;
  background-color: #223651ff;
  border-radius: 8px;
  color: #fff;
  padding: 60px 0px 80px 0px;
  max-height: 200px;
  max-width: 560px;
}

.subdomain-list-header {
  border-bottom: 3px solid #ffffff77;
  padding-bottom: 20px;
  margin: 5px;
}

.static-text {
  margin-top: 8px;
  font-size: 24px;
}

#small-text-box {
  text-align: right;
  width: 72%;
}

.input-group {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

#wide-input {
  width: 100%;
}

.input-group input {
  flex: 1;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.wide-text-box {
  width: 100%;
}

.redirect-btn {
  margin-top: 10px;
  width: 290px;
  height: 54px;
  background-color: #3fbeea;
  border: 1.5px solid #3fbeea;
  color: #223651;
  font-size: 18px;
  transition: 0.15s;
  border-radius: 4px;
  font-weight: 900;
  cursor: pointer;
}

.redirect-btn:hover {
  cursor: pointer;
  background-color: #223651;
  color: #3fbeea;
}

.redirect-input-class {
  display: flex;
  flex-direction: row;
  align-items: left;
  width: 100%;
  color: #fff;
}

.redirect-input-class input {
  height: 35px;
  width: 100%;
  margin: 2px 4px 16px 0;
  font-size: 16px;
  border: 1px solid #ffffff88;
  color: #fff;
  background-color: rgba(255, 255, 255, 0.04);
  border-radius: 4px;
  padding-left: 2px;
  font-size: 24px;
}

.redirect-form {
  display: flex;
  flex-direction: column;
  align-items: left;
  width: 80%;
}

.faded {
  opacity: 0.5;
  color: #555; /* This class will make the text more transparent */
}

.lt-redirect-page-content {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
}

.subdomain-cards {
  width: 33%;
  background-color: #223651ff;
  border-radius: 8px;
  color: #fff;
  padding: 18px;
  display: flex;
  flex-direction: column;
  margin: 50px;
  max-height: fit-content;
}

.subdomain-card {
  background: #223651;
  color: #fff;
  padding: 10px;
  padding-bottom: 35px;
  border-bottom: 3px solid #ffffff77;
  display: flex;
  flex-direction: row;
  /* justify-content: top; */
  justify-content: space-between;
}

.subdomain-card:not(:last-child) {
  margin-bottom: 6px;
}

.subdomain-card:last-child {
  border-bottom: none;
}

.subdomain-card a {
  color: #e8913d;
  cursor: pointer;
}

.subdomain-card a:hover {
  text-decoration: none;
}

.subdomain-active {
  background: #155724;
  width: fit-content;
  padding: 8px 20px;
  border-radius: 8px;
}

.subdomain-inactive {
  background: #db242b;
  width: fit-content;
  padding: 8px 20px;
  border-radius: 8px;
}

.subdomain-card-right img {
  padding-top: 17px;
  width: 30px;
  transition: 0.3s;
  scale: 1;
}

.subdomain-card-right img:hover {
  cursor: pointer;
  scale: 1.2;
}

.duplicate-warning h3 {
  color: #db242b;
  margin: 0px;
  margin-bottom: 5px;
}

#subdomain-btn-disabled {
  opacity: 50%;
  cursor: no-drop;
  background-color: #3fbeea;
  border: 1.5px solid #3fbeea;
  color: #223651;
}
