#disabled-input:hover {
  cursor: not-allowed;
}

.alert-error {
  margin: 5px;
  padding: 10px;
  background-color: #f8d7da; /* Red */
  color: #721c24;
  border-radius: 5px;
  width: 200px;
}
